.footer {
padding: 40px 0;
background: #404040;
    @include media("<=430px") {

    }
}

.footer__inner {
    
    justify-content: space-between;
        width: 100%;
        align-items: center;
        display: flex;
        
    position: relative;
    @include media("<=810px") {
     
    }
    @include media("<=640px") {
        flex-direction: column-reverse;
    }
}

.footer__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    
    @include media("<=810px") {
        flex-direction: column;
        gap: 20px;
    }
}

.footer__nav {
    display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;

@include media("<=810px") {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-left: 0;
}
        @include media("<=640px") {
               
            }
}

.footer__link {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}



.copyright {
  color: #fff;
    font-size: 14px;

    @include media("<=810px") {
        text-align: left;
        margin-left: 0;
    }
}

.footer__text {
    @include media("<=430px") {
        font-size: 14px;
    }
}