.about {
    padding: 60px 0;
    background: #D5EEFF;
    @include media("<=500px") {
        
    }
}

.about__inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    line-height: 1.3;
    padding-top: 40px;
    @include media("<=900px") {
       flex-direction: column;
    }
@include media("<=500px") {
        flex-direction: column;
        align-items: center;
    }
   
}



.about__text {
   
    font-size: 16px;
    line-height: 1.2;
   
    p {
       
    }
}





