.features {
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  
  @include media("<=1440px") {

  }
  
}

.features__inner {
   padding-top: 30px;
   padding-bottom: 30px;
  display: flex;

    gap: 40px;
    justify-content: space-between;
    
    position: relative;
    z-index: 3;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
          gap: 16px;
        }
                @include media("<=810px") {
                  
                }
        @include media("<=500px") {
            flex-direction: column;
            align-items: center;
        }
    &-nowrap {
      flex-wrap: nowrap;
      @include media("<=810px") {
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }    
}

.section__item {

  flex: 0 1 46%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 16px;
 
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=810px") {
     flex: 0 1 48%;
     max-width: 400px;
  }
  @include media("<=500px") {
     flex: 0 1 100%;
  }
 @include media("<=360px") {
   

 }
  span {
    font-weight: 700;
  }
}

.item__image {
  margin-bottom: 40px;
}

.item__big {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 28px;
}

.item__title {
  
   text-transform: uppercase;
  font-size: 18px;
 

@include media("<=1440px") {
  
}
  @include media("<=1280px") {
   
  }

  @include media("<=450px") {
    font-size: 20px;
  }
    @include media("<=380px") {
      
    }
}

.item__text {
  margin: 30px 0;
  
@include media("<=1080px") {

  }
  @include media("<=810px") {
   

  }

  @include media("<=430px") {
   
  }
}

.features__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  line-height: 1.3;
  max-width: 620px;
  text-align: center;
}

.features__text {
  display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.features__image {
  max-width: 383px;
  img {
    width: 100%;
  }
}
