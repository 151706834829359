.hero {
  padding: 160px 0 ;

  position: relative;
//  z-index: 2;
  overflow-x: clip;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    padding: 80px 0;
    
  }
  @include media("<=430px") {
    
  }
  
}

.hero__inner {
 
  
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  @include media("<=1080px") {
    margin: 0 auto;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  @include media("<=630px") {
   
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
  &-about {
      background-image: url(../images/about-bcg.png);
  }
  &-hotels {
      background-image: url(../images/hotels-bcg.png);
  }
}



.hero__content {
  flex: 0 1 100%;
  width: 100%;
  max-width: 580px;
  z-index: 24;
  line-height: 1.2;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
  
    
  }
  @include media("<=810px") {
    max-width: 500px;
    margin: 0 auto;
    line-height: 1.2;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }
    @include media("<=500px") {
     width: 100%;
    }
}

.hero__title {
  margin-bottom: 40px;
  font-size: 32px;
  text-transform: uppercase;
  width: 100%;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
        @include media("<=1440px") {
         
        }
  @include media("<=1280px") {
     font-size: 38px;
    
  }

  @include media("<=1080px") {
    font-size: 32px;
  }
  @include media("<=810px") {
   

    
  }
  @include media("<=500px") {
    font-size: 30px;
   
  }

  @include media("<=410px") {
    font-size: 28px;
  }
}

.hero__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  margin-bottom: 40px;
  line-height: 1.4;
  @include media("<=1280px") {
    
  }
}


.button { 
    text-decoration: none;
    border: none;
    width: 100%;
   max-width: 446px;
   margin: 0 auto;
  display: flex;
    align-items: center;
    justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  padding: 22px;
  width: 100%;
border-radius: 20px;
  border: 2px solid #FF895D;
  background: #FF895D;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 25;

  &:hover {
    background-color: #78BBE6;

  }
    @include media("<=1440px") {
      
    }
        @include media("<=810px") {
         
        }
    @include media("<=430px") {
      
    }
        @include media("<=375px") {
          
        }
        &-form {
          border-radius: 20px;
            border: 2px solid #464646;
        }
      
      }


.hero__image {
  max-width:383px;
  width: 100%;
  img {
   
    width: 100%;
  }
}




