.contacts {
    padding: 60px 0;
    background: #D5EEFF;
}

.contacts__inner  {
    display: flex;

        align-items: center;
        justify-content: center;
   
    @include media("<=810px") {
        flex-direction: column;
        
    }
}

.form__title {
    font-size: 32px;
    text-align: center;
}

.contacts__form {
    width: 100%;
    max-width: 491px;
}

.form {
    display: flex;
   flex-direction: column;
    padding-top: 40px;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
   
    @include media("<=630px") {
        flex-direction: column;
    }
    
}

.form__input {
    width: 100%;
    
    input {
     
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        padding:  12px;
        border: none;
        background: rgba(255, 255, 255, 0.40);
    font-family: "Open Sans", sans-serif;
        @include media("<=430px") {}
    }
    textarea {
        resize: none;
     
        width: 100%;
        font-size: 14px;
            font-weight: 500;
            color: #000;
            padding: 12px;
            border: none;
            background: rgba(255, 255, 255, 0.40);
            font-family: "Open Sans", sans-serif;
    }
    
        
}







