.hotels {
    padding: 20px 0 80px;
}

.hotels__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding-top: 30px;
    @include media("<=840px") {
        flex-direction: column;
        align-items: center;
    }
}

.hotels__item {
    flex: 0 1 31%;
    display: flex;
    flex-direction: column;
    align-items: center;
        overflow: hidden;
        width: 100%;
border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        padding: 20px;
        color: #161616;
@include media("<=810px") {
    max-width: 600px;
}
@include media("<=700px") {
    flex-direction: column;
}
}

.hotels__image {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    @include media("<=700px") {
            max-width: 450px;
        }
    img {
        width: 100%;
        display: block;
    }
}



.hotels__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 0 1 100%;

    @include media("<=840px") {
            
        }
}



.hotels__title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    color: #161616;
    @include media("<=810px") {
        font-size: 24px;
    }
}

.hotels__text {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 20px;
    color: #161616;
    @include media("<=810px") {
      font-size: 14px;
    }
    span {
        font-weight: 600;
    }
}

.hotels__info {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 50px 0 80px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
    }
}

.hotels__info-item {
    border-radius: 20px;
        background: #FFF;
        padding: 20px;
        flex: 0 1 31%;
        @include media("<=810px") {
            flex: 0 1 100%;
            width: 100%;
            max-width: 450px;
        }
}
